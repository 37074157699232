@import './../../../variables.scss';

body {
	background-image: url('https://primopositum.s3.eu-central-1.amazonaws.com/Home/Hero/connections.jpg');
	background-repeat: no-repeat;
	background-size: contain;
}

.violet {
	color: #f72585;
}

.lightblue {
	color: #4895ef;
}

.pink {
	color: #f72585;
}

.buttonLink:hover {
	color: white;
	text-decoration: none;
}

.howitworks .head4 {
	color: #f72585;
}

.boxbulletpoint {
	color: #f72585;
}

.priceFirstRow .head4 {
	color: #f72585;
}

.whitebackground {
	background-color: #ffffff;
	border-radius: 10px 10px 10px 10px;
}

.price {
	background-color: #ebebf9;
}

@include xs {
	body {
		background-size: cover;
	}
	.head {
		color: #3a0ca3;
		font-weight: bold;
		font-family: 'Montserrat';
		font-size: 32px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 40px;
		text-shadow: 0 1px 2px 0 #ffffff;
	}

	.head4 {
		min-height: 48px;
		font-family: 'Montserrat';
		font-size: 14px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 16px;
	}

	.hero {
		min-height: 295px;
		background: linear-gradient(
			90deg,
			#ffffff 0%,
			rgba(255, 255, 255, 0.85) 75%,
			rgba(255, 255, 255, 0) 100%
		);
	}

	.heroFirstRow {
		padding-top: 32px;
	}

	.heroSecondRow {
		padding-top: 24px;
		padding-bottom: 32px;
	}

	.buttonLink {
		color: white;
		height: 48px;
		width: 100%;
		border-radius: 4px;
		border-color: #f72585;
		background-color: #f72585;
		font-size: 20px;
	}

	.banner {
		min-height: 160px;
		background-color: #3a0ca3;
		padding: 40px 0 50px 0;
		text-align: center !important;
	}

	.bannerTitle {
		//height: 24px;
		color: #4895ef;
		font-family: 'Montserrat';
		font-size: 26px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 32px;
		text-align: center !important;
		vertical-align: middle;
		padding-bottom: 6px;
	}

	.bannerTxt {
		//height: 24px;
		color: #ffffff;
		font-family: 'Montserrat';
		font-size: 14px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 16px;
		text-align: center !important;
		vertical-align: middle;
	}

	.howitworks {
		min-height: 1125px;
		background-color: #ffffff;
		//background-color: rgba(63,55,201,0.02);
	}

	.howitworksFirstRow {
		padding-top: 32px;
		text-align: center !important;
	}

	.howitworksSecondRow {
		padding-top: 8px;
		padding-bottom: 32px;
	}

	.howitworkstitle .head4 {
		text-align: left;
		font-size: 32px;
		letter-spacing: 0;
		line-height: 32px;
	}

	.box {
		margin-top: 16px;
		min-height: 295px;
		//width: 348px;
		background-color: #ffffff;
		box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.1);
		border-radius: 10px;
	}

	.boxtitle {
		color: #3a0ca3;
		font-family: 'SF Pro Display';
		font-size: 24px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 29px;
		padding: 24px 24px 0 24px;
		border-radius: 10px;
	}

	.boxtext {
		height: 38px;
		color: #3a0ca3;
		font-family: 'SF Pro Display';
		font-size: 16px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 19px;
		padding: 0 24px 0 24px;
	}

	.boximage {
		margin: 8px 0px 24px 75px;
	}

	.securityintro {
		min-height: 198px;
		background-color: #3a0ca3;
	}

	.securityintroRow {
		padding-top: 24px;
		padding-bottom: 24px;
		text-align: left !important;
	}

	.securityintro .head4 {
		max-width: 918px;
		color: #4895ef;
		font-family: 'Montserrat';
		font-size: 32px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 38px;
	}

	.security {
		//min-height: 836px;
		padding-bottom: 32px;
		background-color: #3a0ca3;
	}

	.security .head4 {
		padding-top: 24px;
		text-align: left;
		font-family: 'Montserrat';
		font-size: 32px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 40px;
	}

	.securityRow {
		text-align: left;
	}

	.securityCol {
		margin: 0;
		padding: 0;
	}

	.securitytext {
		padding-top: 8px;
		margin-bottom: 0px;
		color: #ffffff;
		font-family: Montserrat;
		font-size: 16px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 28px;
	}

	.boxsecimage {
		height: 0;
		width: 0;
		display: none;
		border-radius: 10px;
	}

	.priceFirstRow {
		padding-top: 40px;
		padding-bottom: 24px;
	}

	.priceFirstRow .head4 {
		color: #f72585;
		font-family: 'Montserrat';
		font-size: 32px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 40px;
		text-align: left;
	}

	.priceSecondRow .head4 {
		color: #3a0ca3;
		font-size: 22px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 40px;
		text-align: left;
	}

	.priceThirdRow {
		padding: 24px 0 0 0;
		margin: 0 10px 0 10px;
		text-align: center !important;
	}

	.paymethodhead {
		padding-top: 12px;
		height: 32px;
		color: #4895ef;
		font-family: 'Montserrat';
		font-size: 18px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 32px;
	}

	.pricemainboxsx {
		background-color: #ffffff;
		box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.1);
		padding: 0;
		border-radius: 10px 10px 0px 0px;
	}

	.pricemainboxdx {
		background-color: #ffffff;
		box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.1);
		padding: 0;
		border-radius: 0px 0px 10px 10px;
	}

	.priceForthRow {
		padding-top: 24px;
		padding-bottom: 9px;
	}

	.pricenote {
		color: #3a0ca3;
		font-family: 'Montserrat';
		font-size: 14px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 16px;
		text-align: left;
	}

	.paybox {
		display: none;
	}

	.marginleft {
		display: none;
	}

	.priceimg {
		height: 255px;
		width: 100%;
		border-radius: 0px 0px 10px 10px;
	}

	.ccimage {
		margin-top: 50px;
		margin-bottom: 50px;
		max-width: 310px;
		max-height: 110px;
	}
}

@include sm {
}

@include md {
	body {
		background-size: contain;
	}

	.head {
		color: #3a0ca3;
		font-weight: bold;
		font-family: 'Montserrat';
		font-size: 56px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 68px;
		text-shadow: 0 1px 2px 0 #ffffff;
	}

	.head4 {
		font-family: 'Montserrat';
		font-size: 32px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 38px;
		text-align: center !important;
	}

	.hero {
		min-height: 443px;
		//background: linear-gradient(90deg, #FFFFFF 0%, #FFFFFF 55.02%, rgba(255,255,255,0) 100%);
		background: linear-gradient(
			90deg,
			#ffffff 0%,
			rgba(255, 255, 255, 0.85) 75%,
			rgba(255, 255, 255, 0) 100%
		);
	}

	.heroFirstRow {
		padding-top: 56px;
	}

	.heroSecondRow {
		padding-top: 24px;
		padding-bottom: 32px;
	}

	.buttonLink {
		color: white;
		height: 48px;
		width: 169px;
		border-radius: 4px;
		border-color: #f72585;
		background-color: #f72585;
		font-size: 20px;
	}

	.banner {
		min-height: 160px;
		background-color: #3a0ca3;
		padding: 40px 0 50px 0;
		text-align: center !important;
	}

	.bannerTitle {
		//height: 24px;
		color: #4895ef;
		font-family: 'Montserrat';
		font-size: 32px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 48px;
		text-align: center !important;
		vertical-align: middle;
		padding-bottom: 8px;
	}

	.bannerTxt {
		color: #ffffff;
		font-family: 'Montserrat';
		font-size: 18px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 24px;
		text-align: center !important;
		vertical-align: middle;
	}

	.box {
		min-height: 343px;
		//width: 348px;
		background-color: #ffffff;
		box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.1);
		border-radius: 10px;
	}

	.boximage {
		margin: 24px 0 24px 24px;
	}

	.howitworks {
		min-height: 519px;
		background-color: #ffffff;
	}

	.securityintro {
		min-height: 178px;
		background-color: #3a0ca3;
	}

	.securityintroRow {
		padding-top: 32px;
		padding-bottom: 32px;
		text-align: center !important;
	}

	.securityintro .head4 {
		color: #4895ef;
		font-size: 32px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 38px;
		text-align: center;
	}

	.security {
		//min-height: 691px;
		background-color: #3a0ca3;
	}

	.boxsec {
		min-height: 252px;
		display: inline;
		padding-top: 24px;
	}

	.security .head4 {
		padding-top: 0;
		padding-bottom: 0;
		text-align: left !important;
		float: left;
		width: 340px;
	}

	.securityRow {
		text-align: left;
	}

	.securityCol {
		margin: 0;
		padding: 0;
	}

	.securitytext {
		max-width: 310px;
		padding-top: 8px;
		color: #ffffff;
		font-family: 'Montserrat';
		font-size: 16px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 28px;
	}

	.boxsecimage {
		height: 248px;
		width: 348px;
		display: inline;
		float: right;
	}

	.price {
		min-height: 633px;
		background-color: #ebebf9;
		//background-color: rgba(63,55,201,0.1);
	}

	.priceFirstRow {
		padding-top: 40px;
		text-align: center !important;
	}

	.priceSecondRow .head4 {
		color: #3a0ca3;
		text-align: center !important;
		font-size: 22px;
	}

	.priceThirdRow {
		padding: 24px 0 0 0;
		text-align: center !important;
	}

	.paymethodhead {
		padding-top: 24px;
		height: 32px;
		color: #3a0ca3;
		font-family: 'Montserrat';
		font-size: 18px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 32px;
	}

	.pricemainboxsx {
		background-color: #ffffff;
		box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.1);
		padding-right: 0;
		border-radius: 10px 0px 0px 10px;
	}

	.pricemainboxdx {
		background-color: #ffffff;
		box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.1);
		padding-left: 0;
		padding-right: 0;
		border-radius: 0px 10px 10px 0px;
	}

	.whitebackground {
		background-color: #ffffff;
		z-index: 2;
		position: relative;
		width: 100%;
		height: 100%;
		border-radius: 10px 10px 10px 10px;
	}

	.priceForthRow {
		padding-top: 34px;
	}

	.pricenote {
		color: #3a0ca3;
		font-family: 'Montserrat';
		font-size: 14px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 16px;
		text-align: center;
	}

	.paybox {
		left: 18px;
		margin-top: 48px;
		height: 71px;
		width: 145px;
		border-radius: 8px;
		background-color: #e2eefc; //#4895EF with opacity 0.16
		position: absolute;
		z-index: 3;
		margin-left: 0px;
		text-align: center;
		display: inline;
	}

	.marginleft {
		left: 12px;
		margin-top: 48px;
		height: 71px;
		width: 145px;
		border-radius: 8px;
		background-color: #e2eefc; //#4895EF with opacity 0.16
		position: absolute;
		z-index: 3;
		margin-left: 160px;
		text-align: center;
		display: inline;
	}

	.priceimg {
		height: 280px;
		width: 100%;
		border-radius: 0px 10px 10px 0px;
	}

	.ccimage {
		margin-top: 75px;
		max-width: 319px;
		max-height: 110px;
	}

	.paypaltext {
		margin: auto;
		padding-top: 25px;
		height: 24px;
		width: 50%;
		color: #3a0ca3;
		font-family: 'Montserrat';
		font-size: 16px;
		font-weight: 600;
		letter-spacing: 0;
		line-height: 24px;
		text-align: center;
		opacity: 1;
	}

	.payboxtext {
		margin: auto;
		height: 24px;
		width: 50%;
		color: #3a0ca3;
		font-family: 'Montserrat';
		font-size: 16px;
		font-weight: 600;
		letter-spacing: 0;
		line-height: 24px;
		text-align: center;
		//opacity: 1;
	}
}

@include lg {
	body {
		background-size: contain;
	}

	.securitytext {
		max-width: 560px;
	}

	.securityintro {
		min-height: 142px;
		background-color: #3a0ca3;
	}

	.security .head4 {
		padding-top: 0;
		padding-bottom: 0;
		text-align: left !important;
		float: left;
		width: 580px;
	}

	.paybox {
		left: 18px;
		margin-top: 48px;
		height: 71px;
		width: 200px;
		border-radius: 8px;
		background-color: #e2eefc; //#4895EF with opacity 0.16
		position: absolute;
		z-index: 3;
		margin-left: 0px;
		text-align: center;
		display: inline;
	}

	.marginleft {
		left: 12px;
		margin-top: 48px;
		height: 71px;
		width: 200px;
		border-radius: 8px;
		background-color: #e2eefc; //#4895EF with opacity 0.16
		position: absolute;
		z-index: 3;
		margin-left: 224px;
		text-align: center;
		display: inline;
	}

	.priceimg {
		height: 280px;
		width: 100%;
		border-radius: 0px 10px 10px 0px;
	}

	.ccimage {
		margin-top: 75px;
		max-width: 319px;
		max-height: 110px;
	}

	.paypaltext {
		margin: auto;
		padding-top: 25px;
		height: 24px;
		width: 50%;
		color: #3a0ca3;
		font-family: 'Montserrat';
		font-size: 16px;
		font-weight: 600;
		letter-spacing: 0;
		line-height: 24px;
		text-align: center;
		opacity: 1;
	}

	.payboxtext {
		margin: auto;
		height: 24px;
		width: 50%;
		color: #3a0ca3;
		font-family: 'Montserrat';
		font-size: 16px;
		font-weight: 600;
		letter-spacing: 0;
		line-height: 24px;
		text-align: center;
		//opacity: 1;
	}
}

@include xl {
	html {
		overflow-x: hidden;
	}
	.head {
		color: #3a0ca3;
		font-weight: bold;
		font-family: 'Montserrat';
		font-size: 56px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 68px;
		text-shadow: 0 1px 2px 0 #ffffff;
	}

	.head4 {
		font-family: 'Montserrat';
		font-size: 32px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 38px;
		text-align: center !important;
	}

	.hero {
		min-height: 443px;
		//background: linear-gradient(90deg, #FFFFFF 0%, #FFFFFF 55.02%, rgba(255,255,255,0) 100%);
		background: linear-gradient(
			90deg,
			#ffffff 0%,
			rgba(255, 255, 255, 0.85) 75%,
			rgba(255, 255, 255, 0) 100%
		);
	}

	.heroFirstRow {
		padding-top: 144px;
	}

	.heroSecondRow {
		padding-top: 40px;
		padding-bottom: 90px;
	}

	.buttonLink {
		color: white;
		height: 48px;
		width: 169px;
		border-radius: 4px;
		border-color: #f72585;
		background-color: #f72585;
		font-size: 20px;
	}

	.banner {
		min-height: 112px;
		background-color: #3a0ca3;
		padding: 40px 0 50px 0;
		text-align: center !important;
	}

	.bannerTitle {
		//height: 24px;
		color: #4895ef;
		font-family: 'Montserrat';
		font-size: 32px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 48px;
		text-align: center !important;
		vertical-align: middle;
		padding-bottom: 8px;
	}

	.bannerTxt {
		//height: 24px;
		color: #ffffff;
		font-family: 'Montserrat';
		font-size: 18px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 24px;
		text-align: center !important;
		vertical-align: middle;
	}

	.howitworks {
		min-height: 523px;
		background-color: #ffffff;
		//background-color: rgba(63,55,201,0.02);
	}

	.howitworkstitle {
		text-align: center;
	}

	.howitworkstitle .head4 {
		color: #f72585;
		font-family: 'Montserrat';
		font-size: 32px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 36px;
		text-align: center;
	}

	.howitworksFirstRow {
		padding-top: 48px;
		text-align: center !important;
	}

	.howitworksSecondRow {
		padding-top: 48px;
		padding-bottom: 56px;
	}

	.box {
		height: 295px;
		//width: 348px;
		background-color: #ffffff;
		box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.1);
		border-radius: 10px;
	}

	.boxtitle {
		color: #3a0ca3;
		font-family: 'Montserrat';
		font-size: 24px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 29px;
		padding: 24px 24px 0 24px;
		border-radius: 10px;
	}

	.boxtext {
		height: 38px;
		color: #3a0ca3;
		font-family: 'Montserrat';
		font-size: 16px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 19px;
		padding: 0 24px 0 24px;
	}

	.boximage {
		margin: 8px 85px 24px 85px;
	}

	.securityintro {
		min-height: 172px;
		background-color: #3a0ca3;
	}

	.securityintroRow {
		padding-top: 48px;
		text-align: center !important;
	}

	.securityintro .head4 {
		max-width: 918px;
		color: #4895ef;
	}

	.security {
		//min-height: 691px;
		background-color: #3a0ca3;
	}

	.boxsec {
		padding-top: 0;
		min-height: 529px;
		display: inline;
	}

	.security .head4 {
		padding-top: 12px;
		padding-bottom: 24px;
		text-align: left;
		float: none;
	}

	.securityRow {
		text-align: left;
	}

	.securityCol {
		margin: 0;
		padding: 0;
	}

	.securitytext {
		max-width: 350px;
		padding-top: 12px;
		color: #ffffff;
		font-family: 'Montserrat';
		font-size: 16px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 28px;
		float: none;
	}

	.boxsecimage {
		height: 248px;
		width: 348px;
		display: inline;
		float: none;
	}

	.price {
		min-height: 633px;
		background-color: #ebebf9;
		//background-color: rgba(63,55,201,0.1);
	}

	.priceFirstRow {
		padding-top: 48px;
		text-align: center !important;
	}

	.priceSecondRow .head4 {
		color: #3a0ca3;
		text-align: center !important;
		font-size: 22px;
	}

	.priceThirdRow {
		padding-top: 24px;
		text-align: center !important;
	}

	.paymethodhead {
		padding-top: 24px;
		height: 32px;
		color: #3a0ca3;
		font-family: 'Montserrat';
		font-size: 24px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 32px;
	}

	.pricemainboxsx {
		background-color: #ffffff;
		box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.1);
		padding-right: 0;
		border-radius: 10px 0px 0px 10px;
	}

	.pricemainboxdx {
		background-color: #ffffff;
		box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.1);
		padding-left: 0;
		padding-right: 0;
		border-radius: 0px 10px 10px 0px;
	}

	.whitebackground {
		background-color: #ffffff;
		z-index: 2;
		position: relative;
		width: 100%;
		height: 100%;
		border-radius: 10px 10px 10px 10px;
	}

	.priceForthRow {
		padding-top: 34px;
	}

	.pricenote {
		color: #3a0ca3;
		font-family: 'Montserrat';
		font-size: 14px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 16px;
		text-align: center;
	}

	.paybox {
		left: 18px;
		margin-top: 64px;
		height: 71px;
		width: 200px;
		border-radius: 8px;
		background-color: #e2eefc; //#4895EF with opacity 0.16
		position: absolute;
		z-index: 3;
		margin-left: 9px;
		text-align: center;
		display: inline;
	}

	.marginleft {
		left: 12px;
		margin-top: 64px;
		height: 71px;
		width: 200px;
		border-radius: 8px;
		background-color: #e2eefc; //#4895EF with opacity 0.16
		position: absolute;
		z-index: 3;
		margin-left: 227px;
		text-align: center;
		display: inline;
	}

	.priceimg {
		height: 299px;
		width: 100%;
		border-radius: 0px 10px 10px 0px;
	}

	.ccimage {
		margin-top: 85px;
		max-width: 319px;
		max-height: 110px;
	}

	.paypaltext {
		margin: auto;
		padding-top: 20px;
		height: 32px;
		width: 50%;
		color: #3a0ca3;
		font-family: 'Montserrat';
		font-size: 22px;
		font-weight: 600;
		letter-spacing: 0;
		line-height: 32px;
		text-align: center;
		opacity: 1;
	}

	.payboxtext {
		margin: auto;
		height: 32px;
		width: 50%;
		color: #3a0ca3;
		font-family: 'Montserrat';
		font-size: 22px;
		font-weight: 600;
		letter-spacing: 0;
		line-height: 32px;
		text-align: center;
		//opacity: 1;
	}
}
