@import './../../../variables.scss';

.maincontainer {
	background-color: #3a0ca3;
}

.animated {
	animation-duration: 1s;
	animation-fill-mode: backwards;
	transform-style: preserve-3d;
}

.validationerror {
	top: 1px;
	font-family: 'Montserrat';
	color: #f72585;
	font-size: 14px;
	position: relative;
	float: right;
}

/** intro */
@keyframes intro {
	from {
		opacity: 0;
		transform: perspective(500px) translate3d(0, 0, -50px);
	}

	to {
		opacity: 1;
		transform: none;
	}
}

.intro {
	animation: intro 1s ease-out;
}

/** enterRight */
@keyframes enterRight {
	from {
		opacity: 0;
		transform: perspective(500px) translate3d(20%, 0, 0);
	}

	to {
		opacity: 1;
		transform: none;
	}
}

.enterRight {
	animation-name: enterRight;
}

/** enterLeft */
@keyframes enterLeft {
	from {
		opacity: 0;
		transform: perspective(500px) translate3d(-20%, 0, 0);
	}

	to {
		opacity: 1;
		transform: none;
	}
}

.enterLeft {
	animation-name: enterLeft;
}

/** exitRight */
@keyframes exitRight {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		transform: perspective(500px) translate3d(100%, 0, -100px);
	}
}

.exitRight {
	animation-name: exitRight;
}

/** exitLeft */
@keyframes exitLeft {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		transform: perspective(500px) translate3d(-100%, 0, -100px);
	}
}

.exitLeft {
	animation-name: exitLeft;
}

.rswwrapper {
	min-height: 400px !important;
}

/** Progress Bar */
$progressheight: 5px;
$duration: 3s;

@keyframes stripes {
	from {
		background-position: 1rem 0;
	}
	to {
		background-position: 0 0;
	}
}

@keyframes rainbow {
	0% {
		background-color: var(--red);
	}
	20% {
		background-color: var(--purple);
	}
	40% {
		background-color: var(--yellow);
	}
	60% {
		background-color: var(--green);
	}
	80% {
		background-color: var(--blue);
	}
	100% {
		background-color: var(--indigo);
	}
}

@keyframes grow {
	from {
		width: 0%;
	}
	to {
		width: 100%;
	}
}

.progresswrapper {
	padding-top: 25%;
}

.progress {
	height: $progressheight;
	overflow: hidden;
}

.progressbar {
	height: $progressheight;
	width: 100%;

	.loaded & {
		animation: grow $duration ease, stripes 1s linear infinite,
			rainbow $duration ease infinite;
	}
}

.head {
	color: white;
	font-weight: bold;
}

.titleBox {
	color: red;
	font-weight: bold;
}

.nextBtn {
	color: white;
	border-radius: 4px;
	border-color: #f72585;
	background-color: #f72585;
	font-family: 'Montserrat';
	font-size: 20px;
	line-height: 30px;
	text-align: center;
}

.backBtn {
	color: white;
	border-radius: 4px;
	border-color: #a69bc2;
	background-color: #a69bc2;
	font-family: 'Montserrat';
	font-size: 20px;
	line-height: 30px;
	text-align: center;
}

.backBtn:hover,
.nextBtn:hover,
.backBtn:focus,
.nextBtn:focus,
.backBtn:visited,
.nextBtn:visited {
	color: white;
	text-decoration: none;
}

.innerJumbotron {
	padding: 0 15px 0 15px;
}

.label {
	font-family: 'Montserrat';
}

.titleBox {
	font-family: 'Montserrat';
}
.subtitleBox {
	font-family: 'Montserrat';
}

@include xs {
	.page {
		min-height: 100vh;
		margin-bottom: 0px;
	}

	.stepsSVG {
		text-align: center;
		margin-left: auto;
		margin-right: auto;
		padding-top: 24px;
		padding-bottom: 24px;
	}

	.label {
		font-size: 16px;
		margin-bottom: 4px;
	}

	.titleBox {
		min-height: 40px;
		color: #3a0ca3;
		font-size: 32px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 40px;
		text-align: center;
	}
	.subtitleBox {
		min-height: 38px;
		color: #3a0ca3;
		font-size: 16px;
		font-weight: 500;
		letter-spacing: 0;
		line-height: 24px;
		text-align: center;
		margin-bottom: 0;
	}

	.resultfail {
		color: #ffffff;
		font-family: 'Montserrat';
		font-size: 14px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 16px;
		text-align: center;
	}

	.resultfail {
		color: #ffffff;
		font-family: 'Montserrat';
		font-size: 14px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 16px;
		text-align: center;
		background-color: #b31b60;
	}

	.icon {
		padding-top: 0px;
		padding-bottom: 0px;
		margin: auto;
	}

	.iconsize {
		height: 210px;
		width: 265px;
		margin: auto;
		display: block;
	}

	.pieform {
		color: #3a0ca3;
		font-family: 'Montserrat';
		font-size: 16px;
		font-weight: 500;
		letter-spacing: 0;
		line-height: 24px;
		margin-bottom: 0;
	}

	.pieformfail {
		color: #b31b60;
		font-family: 'Montserrat';
		font-size: 16px;
		font-weight: 500;
		letter-spacing: 0;
		line-height: 24px;
		margin-bottom: 0;
	}

	.date {
		color: #3a0ca3;
		font-family: 'Montserrat';
		font-size: 16px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 24px;
		text-align: center;
	}

	.stats {
		padding-top: 24px;
	}

	.nextBtn {
		color: white;
		border-radius: 4px;
		border-color: #f72585;
		background-color: #f72585;
		font-family: 'Montserrat';
		font-size: 20px;
		line-height: 30px;
		text-align: center;
	}

	.backBtn {
		color: white;
		border-radius: 4px;
		border-color: #a69bc2;
		background-color: #a69bc2;
		font-family: 'Montserrat';
		font-size: 20px;
		line-height: 30px;
		text-align: center;
	}

	.documentbadgesuccess {
		min-height: 32px;
		width: 280px;
		color: #80ffdb;
		background-color: #3a0ca3;
		font-family: 'SF Pro Display';
		font-size: 16px;
		font-weight: 500;
		letter-spacing: 0;
		line-height: 19px;
		text-align: center;
	}

	.documentbadgefail {
		min-height: 32px;
		width: 280px;
		color: #ffffff;
		background-color: #b31b60;
		font-family: 'SF Pro Display';
		font-size: 16px;
		font-weight: 500;
		letter-spacing: 0;
		line-height: 19px;
		text-align: center;
	}

	.separator {
		height: 4px;
		width: 158px;
		border-radius: 2px;
		background-color: #c6bfd6;
		margin: 12px 0 12px 0;
		margin-left: auto;
		margin-right: auto;
	}

	.negativenote {
		color: #645f70;
		font-family: 'Montserrat';
		font-size: 16px;
		font-weight: 500;
		letter-spacing: 0;
		line-height: 24px;
		text-align: center;
	}
}

@include xl {
	.page {
		min-height: 100vh;
		margin-bottom: -64px;
	}

	.stepsSVG {
		text-align: center;
		margin-left: auto;
		margin-right: auto;
		padding-top: 40px;
		padding-bottom: 40px;
	}

	.form {
		background-color: white;
		margin: 0 auto;
		max-width: 320px;
		padding: 20px;
		border: 1px solid #c4c4c4;
		box-sizing: border-box;
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
		border-radius: 6px;
	}

	.label {
		font-size: 16px;
		margin-bottom: 4px;
	}

	.titleBox {
		min-height: 38px;
		color: #3a0ca3;
		font-family: 'Montserrat';
		font-size: 32px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 38px;
	}
	.subtitleBox {
		min-height: 38px;
		color: #3a0ca3;
		font-family: 'Montserrat';
		font-size: 16px;
		font-weight: 500;
		letter-spacing: 0;
		line-height: 19px;
	}

	.nextBtn {
		color: white;
		border-radius: 4px;
		border-color: #f72585;
		background-color: #f72585;
		font-family: 'Montserrat';
		font-size: 20px;
		line-height: 30px;
		text-align: center;
	}

	.backBtn {
		color: white;
		border-radius: 4px;
		border-color: #a69bc2;
		background-color: #a69bc2;
		font-family: 'Montserrat';
		font-size: 20px;
		line-height: 30px;
		text-align: center;
	}

	.icon {
		//padding-top: 32px;
		//padding-bottom: 32px;
		margin: auto;
	}

	.documentbadgesuccess {
		min-height: 32px;
		min-width: 162px;
		color: #80ffdb;
		background-color: #3a0ca3;
		font-family: 'Montserrat';
		font-size: 16px;
		font-weight: 500;
		letter-spacing: 0;
		line-height: 19px;
		text-align: center;
	}

	.documentbadgefail {
		min-height: 32px;
		min-width: 162px;
		color: #ffffff;
		background-color: #b31b60;
		font-family: 'Montserrat';
		font-size: 16px;
		font-weight: 500;
		letter-spacing: 0;
		line-height: 19px;
		text-align: center;
	}
}
