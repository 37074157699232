$text-color: #000000;
// Smartphones
$screen-xs-min: 360px;
// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;
// Small tablets (portrait view)
$screen-md-min: 768px;
// Tablets and small desktops
$screen-lg-min: 992px;
// Large tablets and desktops
$screen-xl-min: 1200px;

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

// XSmall devices
@mixin xs {
	@media (min-width: #{$screen-xs-min}) {
		@content;
	}
}
// Small devices
@mixin sm {
	@media (min-width: #{$screen-sm-min}) {
		@content;
	}
}
// Medium devices
@mixin md {
	@media (min-width: #{$screen-md-min}) {
		@content;
	}
}
// Large devices
@mixin lg {
	@media (min-width: #{$screen-lg-min}) {
		@content;
	}
}
// Extra large devices
@mixin xl {
	@media (min-width: #{$screen-xl-min}) {
		@content;
	}
}
