@import './../../variables.scss';

//navbar
.verifyBtn {
	color: white;
	height: 40px;
	width: 79px;
	border-radius: 4px;
	background-color: #4895ef;
}

.verifyBtn:hover {
	color: white;
	text-decoration: none;
}

.uploadBtn {
	color: white;
	height: 40px;
	width: 84px;
	border-radius: 4px;
	background-color: #f72585;
}

.uploadBtn:hover {
	color: white;
	text-decoration: none;
}

.lightblue {
	color: #80ffdb;
}

.copyright {
	height: 16px;
	color: #80ffdb;
	font-family: 'Montserrat';
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 0;
	line-height: 16px;
}

.piva {
	height: 16px;
	color: #80ffdb;
	font-family: 'Montserrat';
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 0;
	line-height: 16px;
}

.footerBtn {
	height: 16px;
	color: #80ffdb;
	font-family: 'Montserrat';
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 0;
	text-decoration: underline;
	border-width: 0;
	padding-top: 0;
	padding-bottom: 0;
}

.footerBtn:hover {
	text-decoration: underline;
	color: #4895ef;
}

.footer {
	position: relative;
	bottom: 0;
	width: 100%;
	height: 180px;
	line-height: 32px;
	background-color: #3a0ca3;
}

.footermenu {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.footerBtn:focus {
	box-shadow: none !important;
}

.footerBtn {
	line-height: 34px;
	padding-left: 0;
}

//footer
@include xs {
	.footer {
		position: relative;
		bottom: 0;
		width: 100%;
		height: 220px;
		line-height: 64px;
		background-color: #3a0ca3;
	}

	.footerBtn {
		height: 28px;
	}

	.piva {
		display: block;
		margin-bottom: 14px;
	}
}

@include md {
	.footer {
		position: relative;
		bottom: 0;
		width: 100%;
		height: 106px;
		line-height: 64px;
		background-color: #3a0ca3;
	}

	.footermenu {
		display: inline;
		width: 100%;
		line-height: 0px;
	}

	.piva {
		display: inline;
	}
}

@include lg {
	.footer {
		position: relative;
		bottom: 0;
		width: 100%;
		height: 64px;
		line-height: 64px;
		background-color: #3a0ca3;
	}

	.footermenu {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
	}

	.footerBtn {
		line-height: 16px;
		margin-top: 22px;
	}
}
