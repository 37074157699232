@import './../../../variables.scss';

.maincontainer {
	background-color: #ffffff;
}

@include xs {
	.maincontainer {
		padding-bottom: 56px;
	}

	.page {
		min-height: 100vh;
		margin-bottom: 0px;
	}

	.head {
		padding-top: 24px;
		margin-bottom: 0px;
		min-height: 40px;
		color: #3a0ca3;
		font-family: 'Montserrat';
		font-size: 32px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 40px;
	}

	.subtitle {
		padding-top: 16px;
		margin-bottom: 0px;
		min-height: 38px;
		color: #3a0ca3;
		font-size: 20px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 30px;
	}

	.sectiontitle {
		padding-top: 32px;
		padding-bottom: 8px;
		color: #37343d;
		font-family: 'Montserrat';
		font-size: 20px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 30px;
	}

	.paragraph {
		padding-top: 8px;
		color: #37343d;
		font-family: 'Montserrat';
		font-size: 18px;
		font-weight: 500;
		letter-spacing: 0;
		line-height: 28px;
	}

	.member {
		padding-top: 48px;
		//border-bottom: #4895EF 2px solid;
	}
}

@include xl {
	.maincontainer {
		padding-bottom: 112px;
	}

	.page {
		min-height: 100vh;
		margin-bottom: -56px;
	}

	.head {
		padding-top: 74px;
		font-size: 56px;
		line-height: 68px;
	}

	.subtitle {
		padding-top: 48px;
		font-size: 32px;
		line-height: 40px;
	}

	.sectiontitle {
		padding-top: 24px;
		padding-top: 16px;
		font-size: 20px;
		line-height: 30px;
	}

	.paragraph {
		font-size: 18px;
		line-height: 28px;
	}

	.userprofile {
		padding-top: 48px;
	}
}
