body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap');

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

/* CUSTOM BOOTSTRAP COMPONENTS */
.jumbotron {
	padding: 40px 0 40px 0;
	border-radius: 8px;
	box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.2);
	margin-bottom: 80px;
}

/* CUSTOM NAVBAR PURLE */
.navbar-purple {
	background-color: #3a0ca3;
	border-color: #80ffdb;
	box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.5);
}
.navbar-purple .navbar-brand {
	color: #4895ef;
}
.navbar-purple .navbar-brand:hover,
.navbar-purple .navbar-brand:focus {
	color: #4895ef;
}
.navbar-purple .navbar-nav > li > a {
	color: #80ffdb;
	text-align: center;
}
.navbar-purple .navbar-nav > li > a:hover,
.navbar-purple .navbar-nav > li > a:focus {
	color: #80ffdb;
}
.navbar-purple .navbar-nav .active > a,
.navbar-purple .navbar-nav .active > a:hover,
.navbar-purple .navbar-nav .active > a:focus {
	color: #ffe6d1;
	background-color: #80ffdb;
}
.navbar-purple .navbar-nav .open > a,
.navbar-purple .navbar-nav .open > a:hover,
.navbar-purple .navbar-nav .open > a:focus {
	color: #80ffdb;
	background-color: #80ffdb;
}
.navbar-purple .navbar-nav .open > a .caret,
.navbar-purple .navbar-nav .open > a:hover .caret,
.navbar-purple .navbar-nav .open > a:focus .caret {
	border-top-color: #ffe6d1;
	border-bottom-color: #ffe6d1;
}
.navbar-purple .navbar-nav > .dropdown > a .caret {
	border-top-color: #80ffdb;
	border-bottom-color: #80ffdb;
}
.navbar-purple .navbar-nav > .dropdown > a:hover .caret,
.navbar-purple .navbar-nav > .dropdown > a:focus .caret {
	border-top-color: #ffe6d1;
	border-bottom-color: #ffe6d1;
}
.navbar-purple .navbar-toggle {
	border-color: #3a0ca3;
}
.navbar-purple .navbar-toggle:hover,
.navbar-purple .navbar-toggle:focus {
	background-color: #3a0ca3;
	outline: none !important;
}
.navbar-purple .navbar-toggle .icon-bar {
	background-color: #48bfe3;
}
.navbar-purple .navbar-toggler-icon {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28 72,191,227, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='4' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-toggler {
	padding: 0.25rem 0.75rem;
	font-size: 1.25rem;
	line-height: 1;
	background-color: transparent;
	border: 1px solid transparent;
	border-radius: 0.25rem;
}
.navbar-collapse {
	text-align: center;
}
.btn-link:hover {
	text-decoration: none;
}
